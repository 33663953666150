import React, {useContext} from "react";
import "./Footer.scss";
import {Fade} from "react-reveal";
import emoji from "react-easy-emoji";
import StyleContext from "../../contexts/StyleContext";
import { cheetSheet } from "../../portfolio";
import { Link } from "react-router-dom";

export default function Footer() {
  const {isDark} = useContext(StyleContext);
  return (
    <Fade bottom duration={1000} distance="5px">
      <div className="footer-div">
        <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          {emoji("Made with ❤️ by me")}
        </p>
        {/* <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          Theme by{" "}
          <a href="https://github.com/saadpasta/developerFolio">
            developerFolio
          </a>
        </p> */}
         
        {/* Rendering links */}
        {cheetSheet.map((cheet, index) => (
          <p key={index} className={isDark ? "dark-mode footer-text" : "footer-text"}>
            <Link to={cheet.link}>{cheet.title}</Link>
          </p>
        ))}
      </div>
    </Fade>
  );
}
