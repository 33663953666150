// CheerioCheatSheet.js

import React, {useEffect} from "react";
import Prism from "prismjs";
import 'prismjs/themes/prism.css'

const Cheerio = () => {
  useEffect(() => {
    // Manually call Prism.highlightAll() after the component mounts to ensure syntax highlighting
    Prism.highlightAll();
  }, []);
  return (
    <div className="cheerio-cheat-sheet">
      <h2>Cheerio Cheat Sheet</h2>
      <pre>
       <code className="language-javascript">
        {`
                // Import Cheerio
                const cheerio = require('cheerio');

                // Load HTML
                const html = \`
                  <div id="container">
                    <p class="intro">Hello</p>
                    <p>World</p>
                  </div>
                \`;

                // Load HTML into Cheerio
                const $ = cheerio.load(html);

                // Selectors
                $('#container');            // Selects the element with ID 'container'
                $('.intro');                // Selects elements with class 'intro'
                $('p');                     // Selects all 'p' elements

                // Traversing
                $('.intro').parent();       // Gets the parent element of elements with class 'intro'
                $('#container').children();// Gets the children elements of the element with ID 'container'
                $('#container').find('p');  // Finds descendant 'p' elements within the element with ID 'container'

                // Getting Data
                $('.intro').text();         // Gets the text content of elements with class 'intro'
                $('p').html();              // Gets the HTML content of 'p' elements
                $('a').attr('href');        // Gets the value of the 'href' attribute of 'a' elements

                // Filtering
                $('p').eq(0);                // Reduces the set of 'p' elements to the one at index 0
                $('p').first();              // Reduces the set of 'p' elements to the first one
                $('p').last();               // Reduces the set of 'p' elements to the last one

                // Manipulating
                $('p').addClass('highlight');    // Adds the class 'highlight' to 'p' elements
                $('.intro').removeClass('intro');// Removes the class 'intro' from elements with class 'intro'
                $('a').removeAttr('target');     // Removes the 'target' attribute from 'a' elements
                $('p').appendTo('#container');   // Appends 'p' elements to the element with ID 'container'
                $('span').prependTo('p');        // Prepends 'span' elements to 'p' elements

                // Iteration
                $('p').each((index, element) => {
                    console.log(index, $(element).text()); // Iterates over 'p' elements and logs their text content
                });
                `}
      </code>
      </pre>
    </div>
  );
};

export default Cheerio;
