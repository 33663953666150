import React from "react";
import { IconContext } from "react-icons";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./App.scss";
import Main from "./containers/Main";
import Cheerio from "./containers/cheetSheet/Cheerio";

function App() {
  return (
    <BrowserRouter>
      <IconContext.Provider value={{ color: "#91989F", className: 'react-icons-hover' }}>
        <div>
          <div className="elfsight-app-a9a351ce-b5d5-4c10-b3da-4916c70362f1" data-elfsight-app-lazy></div>
          <Routes>
           <Route index element={<Main />} />
            <Route path="/" exact element={<Main />} />
            <Route path="/cheetsheets" element={<Cheerio/>} />
            <Route path="/cheetsheets/cheerio" element={<Cheerio/>} />
           
            
          </Routes>
        </div>
      </IconContext.Provider>
    </BrowserRouter>
  );
}

export default App;
